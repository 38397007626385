import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "toggle" ]

  initialize() {
    this.resetMenu();
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  resetMenu() {
    if (this.isOpen) { // open
      this.toggleTarget.classList.add('-open');
      this.menuTarget.classList.add('-open');
    } else { // closed 
      this.toggleTarget.classList.remove('-open');
      this.menuTarget.classList.remove('-open');
    }
  }

  get isOpen() {
    return (this.data.get("isOpen") === "true");
  }

  set isOpen(value) {
    this.data.set("isOpen", !!value);
    this.resetMenu();
  }
}